import logo from './logo.svg';
import './App.css';
import Navigation from "./Components/Routes/Navigation";

function App() {
  return (
    <Navigation>
      <div>
        abcdefghijklmnopqrstuvwxyz
      </div>
    </Navigation>
  );
}

export default App;
