class Config {
  constructor(config) {
    this._config = {
      cognito: {
        userPoolId: "localhost",
        userPoolClientId: 5432,
        region: "myuser",
      },
      api: {
        endpoint:
          "https://n1f9kahz04.execute-api.us-east-1.amazonaws.com/Development/order",
      },
    };
  }

  getCognitoUserPoolId() {
    return this._config.cognito.userPoolId;
  }

  getCognitoUserPoolClientId() {
    return this._config.cognito.userPoolClientId;
  }

  getCognitoRegion() {
    return this._config.cognito.region;
  }

  getApiEndpoint() {
    return this._config.api.endpoint;
  }
}

const config = new Config();

export default config;
