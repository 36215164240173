import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { Tabs, Tab, Divider, useTheme, useMediaQuery } from "@mui/material";
import { fetchAuthSession } from '@aws-amplify/auth';
import { useAuthenticator } from "@aws-amplify/ui-react";
import config from "../../config";

const footers = [];

export default function Ordering() {
  const theme = useTheme();
  const [timers, setTimers] = useState({});
  const [selectedTab, setSelectedTab] = useState("All");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getStatusBorderStyle = (status) => {
    // Define colors based on order status
    switch (status) {
      case "pending":
        return "4px solid rgb(255, 215, 64)"; // Yellow
      case "completed":
        return "4px solid rgb(144, 238, 144)"; // Light Green
      case "canceled":
        return "4px solid rgb(255, 99, 71)"; // Tomato
      case "pending payment":
        return "4px solid rgb(70, 130, 180)"; // Steel Blue
      case "pending link":
        return "4px solid rgb(255, 69, 0)"; // Red-Orange
          // Add more cases for other statuses as needed
      default:
        return "4px solid rgb(196, 19, 26)"; // Default color for unknown status
    }
  };

  const fetchData = async () => {
    const authSession = await fetchAuthSession();
    const idToken = authSession.tokens.idToken.toString();

    try {
      // Replace 'your_auth_token' with the actual authentication token
      const authToken = idToken;
      const endpoint = config.getApiEndpoint();

      const response = await fetch(`${endpoint}?all="All"`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:3000',
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndSetupTimers = async () => {
      try {
        const data = await fetchData();

        if (data) {
          setOrders(data);

          // Start timers when the component mounts
          const newTimers = {};
          data.forEach((order) => {
            newTimers[order.order_id] = startTimer(order.order_id, order.created_on);
          });
          setTimers(newTimers);
      
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      
    };
    
    fetchDataAndSetupTimers();

    // Clean up timers when the component unmounts
    return () => {
      Object.values(timers).forEach((timer) => clearInterval(timer));
    };
    
    
  }, []); // Make sure to re-run effect when orders change

  const convertToISOFormat = (dateString) => {
    const [month, day, yearTime] = dateString.split("/");
    const [year, time] = yearTime.split(" ");
    const [hour, minute, second] = time.split(":");

    const isoFormat = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}T${hour}:${minute}:${second}`;
    return isoFormat;
  };

  const startTimer = (orderId, createdOn) => {
    const startTime = new Date(convertToISOFormat(createdOn)).getTime() / 1000;
    return setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedSeconds = currentTime - startTime;
      const elapsedMinutes = Math.floor(elapsedSeconds / 60);
      const remainingSeconds = elapsedSeconds % 60;

      // Update the state to trigger a re-render and display the updated timer
      setTimers((prevTimers) => ({
        ...prevTimers,
        [orderId]: { minutes: elapsedMinutes, seconds: remainingSeconds },
      }));
    }, 1000);
  };

  return (
    <div>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {user?.signInDetails?.loginId}
          </Typography>
          <nav>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Home
            </Link>
          </nav>
          <Button onClick={signOut} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Cerrar sesión
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant={isSmallScreen ? "h4" : "h3"}
          align="left"
          color="text.primary"
          gutterBottom
        >
          Ordenes
        </Typography>
        <Divider />
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant={isSmallScreen ? "fullWidth" : "standard"}
        >
          <Tab label="All" value={"All"} />
          <Tab label="Pending" value={"Pending"} />
          <Tab label="Completed" value={"Completed"} />
        </Tabs>
        <Divider />
      </Container>
      {/* End hero unit */}
      <Container component="main">
        <Grid container spacing={4} alignItems="flex-end">
          {orders && orders.filter((order) => {
            if (selectedTab === "All") {
              return order
            } else {
              return order.status === selectedTab.toLowerCase();
            }
          }).map((order) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={order.order_id} xs={12} sm={8} md={3}>
                <Card sx={{ border: getStatusBorderStyle(order.status) }}>
                  <CardHeader
                    title={`Order # ${order.order_id}`}
                    subheader={order.customer.name}
                    titleTypographyProps={{ align: "center" }}
                    action={order.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent sx={{ height: 225 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      {order.status === "completed" ? (
                        <Typography
                          component="h2"
                          variant="h4"
                          color="text.primary"
                        >
                          {order.status}
                        </Typography>
                      ) : (
                        <Typography
                          component="h2"
                          variant="h4"
                          color="text.primary"
                        >
                          {`${timers[order.order_id]?.minutes || 0} minutes ${
                            timers[order.order_id]?.seconds || 0
                          } seconds`}
                        </Typography>
                      )}
                    </Box>
                    <ul>
                      {order.items.slice(0, 3).map((item) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={item.sku}
                        >
                          {item.name}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={
                        order.status === "completed" ? "outlined" : "contained"
                      }
                    >
                      Visualizar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                    first_name
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* End footer */}
    </div>
  );
}
