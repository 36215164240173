import React from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import Home from "../Main/Home";
import Login from "../Authentication/Login";
import Ordering from "../Main/Ordering";
import NotFound from "../Main/NotFound";
import ProtectedRoute from "../CustomRoute/Protected";

const Navigation = () => {
  return (
    <Authenticator.Provider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/home" element={ <ProtectedRoute element={<Home />} />} />
          <Route path="/login" element={ <Login/> } />
          <Route path="/orders" element={ <ProtectedRoute element={<Ordering />} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </Authenticator.Provider>
  );
};

export default Navigation;
