// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(37, 41, 75)',
      light: 'rgb(67, 71, 105)',
      dark: 'rgb(26, 28, 51)',
    },
    secondary: {
      main: 'rgb(196, 19, 26)',
      light: 'rgb(229, 85, 92)',
      dark: 'rgb(135, 12, 17)',
    },
    additional: {
      one: 'rgb(107, 111, 145)',
      two: 'rgb(157, 161, 185)',
      three: 'rgb(75, 41, 37)',
      four: 'rgb(26, 196, 19)',
      five: 'rgb(105, 111, 145)',
      six: 'rgb(41, 48, 75)',
      seven: 'rgb(169, 161, 29)',
      eight: 'rgb(196, 121, 19)',
      nine: 'rgb(75, 41, 26)',
      ten: 'rgb(26, 196, 185)',
    },
  },
});

export default theme;
