import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ProtectedRoute = ({ element }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  if (authStatus === "configuring") {
    return "Loading...";
  }

  if (authStatus !== "authenticated") {
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
