import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, useNavigate  } from "react-router-dom";


function Home(props) {
    const navigate = useNavigate();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const handleButtonClick = () => {
      console.log("clicked");
      navigate('/orders');
    };
    
    return (
        <div>
                <main>
                  <h1>Hello {user?.username}</h1>
                  <button onClick={signOut}>Sign out</button>
                  <button onClick={handleButtonClick}>ver ordenes</button>
                </main>
        </div>
    );
}

export default Home;